import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router';

import { Helmet } from 'react-helmet';
import loadable from '@loadable/component';
import { ThemeProvider } from '@emotion/react';
import { ThemeProvider as NewskitThemeProvider, ToastProvider } from 'newskit';

import { NavigationWithDataWrapper } from '@times-web/newskit-components';
import { isColdProspect, Paywall } from '@times-web/paywall';
import { getTakeoverRoutes } from '@times-web/components-editorial';
import {
    Capping,
    NextAndPrevArticle,
    Footer,
    SubscriptionOfferModal,
    MarketingPreferencesModal,
    MarketingPreferencesModalSwG,
    FreeArticlePromotionAndArticleMeter,
    AccountErrorModalSwG,
    GlideProfilePage,
    SharedTokenFooterBanner,
    SharedTokenHeaderBanner,
    VerifyEmailBanner,
    GoogleSubscriptionLinking,
    ResubscribeExperiment,
} from '@times-web/components-global';
import {
    TimesWebLightTheme,
    getChannelRoutes,
    Instrumentation,
    OptimizelyWeb,
    Sourcepoint,
    useActiveSegment,
    useIsGoogleShowcase,
    getDateInfo,
    useGetDate,
    usePageData,
    hasPoppy,
    useIsIdentified,
} from '@times-web/utils';
import cookies from 'js-cookie';

const EurosHub = loadable(() => import('@times-web/components-editorial'), {
    resolveComponent: (module) => module.EurosHub,
});
const WorldCupHub = loadable(() => import('@times-web/components-editorial'), {
    resolveComponent: (module) => module.WorldCupHub,
});
const Interactive = loadable(() => import('@times-web/components-editorial'), {
    resolveComponent: (module) => module.Interactive,
});
const TakeoverPage = loadable(() => import('@times-web/components-editorial'), {
    resolveComponent: (module) => module.TakeoverPage,
});
const BenefitsCarousel = loadable(
    () => import('@times-web/components-editorial'),
    {
        resolveComponent: (module) => module.BenefitsCarousel,
    }
);
const StandalonePuzzlePage = loadable(() => import('@times-web/page-puzzles'), {
    resolveComponent: (module) => module.StandalonePuzzlePage,
});

export const TheTimes: React.FC = () => {
    const date = useGetDate();
    const { isSunday } = getDateInfo(date);
    const { isSegmentActive } = useActiveSegment();
    const isGoogleShowcase = useIsGoogleShowcase();
    const { pathname } = useLocation();
    const isWelcomePage = pathname === '/account/welcome';
    const isUsEdition = pathname === '/us';
    const isUkEdition = pathname === '/';
    const hasPoppyNav = hasPoppy();
    const isIdentified = useIsIdentified();
    const isUserRegistering = isColdProspect();

    useEffect(() => {
        if (!window.pageViewId) {
            window.pageViewId = `nuk:${
                window.crypto.randomUUID
                    ? window.crypto.randomUUID()
                    : Math.floor(Math.random() * 1000).toString() + Date.now()
            }`;
        }

        const countryCodeCookie = cookies.get('nuk_customer_country_code');
        const locationCookie = cookies.get('nuk_customer_location_hint');
        const regionCodeCookie = cookies.get('nuk_customer_region_code');

        if (!window.nuk) {
            window.nuk = {};
        }

        window.nuk.geoInfo = {
            countryCode: countryCodeCookie,
            locationHint: locationCookie,
            regionCode: regionCodeCookie,
        };
    }, []);

    const handleGlobalNav = (isOpen: boolean) => {
        const body = document.querySelector('body');
        if (body) {
            body.style.overflow = isOpen ? 'hidden' : 'inherit';
        }
    };
    const { preloadedData } = usePageData();

    const getPathId = (path: string) => {
        const pathId = path.split('-');
        return pathId[pathId.length - 1];
    };
    const isNotHardCodedTakeoverRoute = getTakeoverRoutes().find(
        (path) => getPathId(path) === getPathId(pathname)
    );

    return (
        <ThemeProvider theme={TimesWebLightTheme as any}>
            <Instrumentation>
                {isSegmentActive('googleSubscriptionLinking') && (
                    <GoogleSubscriptionLinking />
                )}
                <Helmet titleTemplate="%s | The Times and The Sunday Times">
                    <meta name="robots" content="max-image-preview:large" />
                </Helmet>
                {isSegmentActive('cookieConsent') && <Sourcepoint />}
                {isSegmentActive('optimizelyScript') && <OptimizelyWeb />}
                <ToastProvider />
                <Switch>
                    <Route
                        path={['/edition/:date(\\d\\d\\d\\d-\\d\\d-\\d\\d)']}
                        exact
                        component={loadable(
                            () => import('@times-web/page-edition')
                        )}
                    />
                    {isSegmentActive('reactPage') && (
                        <>
                            <Route
                                path={['/', '/_TP_', '/_tp_']}
                                exact
                                component={loadable(
                                    () => import('@times-web/page-channel')
                                )}
                            />
                            <Route
                                path="/account"
                                component={loadable(
                                    () => import('@times-web/account')
                                )}
                            />
                            <Route
                                path="/appleclassical"
                                component={loadable(
                                    () =>
                                        import(
                                            '@times-web/components-editorial'
                                        ),
                                    {
                                        resolveComponent: (module) =>
                                            module.AppleClassical,
                                    }
                                )}
                            />
                            <Route
                                path="/apps-qr-code"
                                component={loadable(
                                    () => import('@times-web/apps-qr-code')
                                )}
                            />
                            <Switch>
                                <Route
                                    path="/uk-university-rankings/puzzle/:type"
                                    component={() => (
                                        <NewskitThemeProvider
                                            theme={TimesWebLightTheme}
                                        >
                                            <StandalonePuzzlePage />
                                        </NewskitThemeProvider>
                                    )}
                                />
                                <Route
                                    path="/uk-university-rankings/:id"
                                    component={() => <GlideProfilePage />}
                                />
                            </Switch>
                            <Route
                                path="/best-schools-league-table/:id"
                                component={() => <GlideProfilePage />}
                            />
                            <Route
                                exact
                                path={getChannelRoutes()}
                                component={loadable(
                                    () => import('@times-web/page-channel')
                                )}
                            />
                            <Route
                                path={'/preview/channel/:id'}
                                component={loadable(
                                    () => import('@times-web/page-channel')
                                )}
                            />
                            <Route
                                path="/help"
                                component={loadable(
                                    () => import('@times-web/help')
                                )}
                            />
                            <Route
                                path="/interactives/component"
                                component={() => (
                                    <NewskitThemeProvider
                                        theme={TimesWebLightTheme}
                                    >
                                        <Interactive />
                                    </NewskitThemeProvider>
                                )}
                            />
                            <Route
                                path="/interactives"
                                component={loadable(
                                    () => import('@times-web/page-interactives')
                                )}
                            />
                            <Route
                                path="/podcasts"
                                component={loadable(
                                    () => import('@times-web/podcasts')
                                )}
                            />
                            <Route
                                path="/search"
                                component={loadable(
                                    () => import('@times-web/search')
                                )}
                            />
                            <Route
                                path={['/_TP_/puzzles', '/puzzles']}
                                component={loadable(
                                    () => import('@times-web/page-puzzles')
                                )}
                            />
                            <Route
                                path="/radio"
                                component={loadable(
                                    () => import('@times-web/radio')
                                )}
                            />
                            <Route
                                path="/sport/football/world-cup/fixtures-results"
                                component={() => (
                                    <NewskitThemeProvider
                                        theme={TimesWebLightTheme}
                                    >
                                        <WorldCupHub />
                                    </NewskitThemeProvider>
                                )}
                            />
                            <Route
                                path="/sport/football/euro-2024"
                                component={() => (
                                    <NewskitThemeProvider
                                        theme={TimesWebLightTheme}
                                    >
                                        <EurosHub />
                                    </NewskitThemeProvider>
                                )}
                            />
                            <Route
                                path={getTakeoverRoutes()}
                                component={() => (
                                    <NewskitThemeProvider
                                        theme={TimesWebLightTheme}
                                    >
                                        <TakeoverPage />
                                    </NewskitThemeProvider>
                                )}
                            />
                            {preloadedData?.takeoverPage &&
                                !isNotHardCodedTakeoverRoute && (
                                    <Route
                                        path={['/article', '*/article']}
                                        component={() => (
                                            <NewskitThemeProvider
                                                theme={TimesWebLightTheme}
                                            >
                                                <TakeoverPage />
                                            </NewskitThemeProvider>
                                        )}
                                    />
                                )}
                            <Route
                                path="/topic/:slug"
                                component={loadable(
                                    () => import('@times-web/page-topic')
                                )}
                            />
                        </>
                    )}
                </Switch>
                {isSegmentActive('capping') && <Capping />}
                {isSegmentActive('reactPaywall') && (
                    <NewskitThemeProvider theme={TimesWebLightTheme}>
                        <Paywall />
                    </NewskitThemeProvider>
                )}
                {isSegmentActive('returningUserSubscriptionOffer') &&
                    !isUserRegistering && (
                        <NewskitThemeProvider theme={TimesWebLightTheme}>
                            <SubscriptionOfferModal />
                        </NewskitThemeProvider>
                    )}
                {isSegmentActive('marketingPreferencesModal') &&
                    isGoogleShowcase && (
                        <NewskitThemeProvider theme={TimesWebLightTheme}>
                            <MarketingPreferencesModal />
                        </NewskitThemeProvider>
                    )}
                {isSegmentActive('freeArticlePromotion') && (
                    <NewskitThemeProvider theme={TimesWebLightTheme}>
                        <FreeArticlePromotionAndArticleMeter />
                    </NewskitThemeProvider>
                )}
                {isSegmentActive('marketingPreferencesModalSwg') &&
                    isWelcomePage && (
                        <NewskitThemeProvider theme={TimesWebLightTheme}>
                            <MarketingPreferencesModalSwG />
                        </NewskitThemeProvider>
                    )}
                {isSegmentActive('accountErrorModalSwg') && (
                    <NewskitThemeProvider theme={TimesWebLightTheme}>
                        <AccountErrorModalSwG isVisible={false} />
                    </NewskitThemeProvider>
                )}
                {isSegmentActive('footer') && (
                    <NewskitThemeProvider theme={TimesWebLightTheme}>
                        <Footer />
                    </NewskitThemeProvider>
                )}
                {isSegmentActive('sharedTokenFooterBanner') && (
                    <NewskitThemeProvider theme={TimesWebLightTheme}>
                        <SharedTokenFooterBanner />
                    </NewskitThemeProvider>
                )}
                {isSegmentActive('verifyEmailBanner') && (
                    <NewskitThemeProvider theme={TimesWebLightTheme}>
                        <VerifyEmailBanner />
                    </NewskitThemeProvider>
                )}
                {isSegmentActive('newNav') && (
                    <NavigationWithDataWrapper
                        isLoggedIn={isIdentified}
                        isSunday={isSunday}
                        isUsEdition={isUsEdition}
                        isUkEdition={isUkEdition}
                        hasPoppy={hasPoppyNav}
                        onClick={handleGlobalNav}
                    />
                )}
                {isSegmentActive('nextAndPrevArticle') && (
                    <NewskitThemeProvider theme={TimesWebLightTheme}>
                        <NextAndPrevArticle />
                    </NewskitThemeProvider>
                )}
                {isSegmentActive('sharedTokenHeaderBanner') && (
                    <NewskitThemeProvider theme={TimesWebLightTheme}>
                        <SharedTokenHeaderBanner />
                    </NewskitThemeProvider>
                )}
                {isSegmentActive('benefitsRail') && (
                    <NewskitThemeProvider theme={TimesWebLightTheme}>
                        <BenefitsCarousel isChannelHeading />
                    </NewskitThemeProvider>
                )}
                {isSegmentActive('resubscribeExperiment') && (
                    <NewskitThemeProvider theme={TimesWebLightTheme}>
                        <ResubscribeExperiment />
                    </NewskitThemeProvider>
                )}
            </Instrumentation>
        </ThemeProvider>
    );
};
