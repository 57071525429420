import React from 'react';
import { styled, getMediaQueryFromTheme, css } from 'newskit';

interface PopUpContainerProps {
    showResubscribeBannerForTesting?: boolean;
}

// The default display value of "PopUpContainer" is "none"
// The value of "display" is changed to "flex" from Optimizely when the user is eligible for the experiment
export const PopUpContainer = styled.div<PopUpContainerProps>`
    bottom: 0;
    box-shadow: 0px 10px 32px 8px rgba(0, 0, 0, 0.25);
    height: auto;
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 9;
    flex-direction: column;
    align-items: stretch;
    background: #fff;
    padding: 0 8px 35px 8px;
    display: none;

    ${getMediaQueryFromTheme('md', 'lg')} {
        padding: 0 15px 22px 15px;
    }

    ${getMediaQueryFromTheme('xs', 'md')} {
        padding: 0 15px 52px 15px;
    }
`;

export const CloseButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

export const CloseButton = styled.button`
    background-color: transparent;
    border: none;
    padding: 16px;
    cursor: pointer;

    svg {
        fill: #333333;
    }

    ${getMediaQueryFromTheme('xs', 'lg')} {
        padding: 16px 8px 12px 16px;
    }
`;

export const BannerTitleContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 0 43px 0;
    padding-top: 56px;
    ${getMediaQueryFromTheme('xs', 'sm')} {
        margin: 0 0 35px 0;
    }
`;

export const BannerTitle = styled.div`
    font-family: 'Times Modern', serif;
    font-style: normal;
    font-weight: 400;
    color: #01000d;
    text-align: center;
    font-size: 26px;

    span {
        display: inline;
    }

    ${getMediaQueryFromTheme('sm', 'xl')} {
        font-size: 22px;
    }

    ${getMediaQueryFromTheme('xs', 'md')} {
        span {
            display: block;
        }
    }

    ${getMediaQueryFromTheme('xs', 'sm')} {
        font-size: 18px;
    }
`;

export const OffersWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 21px;
    align-items: stretch;
`;

export const OfferLabel = styled.div`
    position: absolute;
    top: 0;
    left: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 119px;
    height: 26px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 23px;
    background: #212121;
    transform: translateY(-50%);

    span {
        font-family: 'Times Modern', serif;
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    ${getMediaQueryFromTheme('xs', 'sm')} {
        width: 90%;
        max-width: 150px;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

export const OfferContainer = styled.div`
    border: 1px solid #C2C2C2;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1;
    max-width: 432px;
`;

export const OfferContent = styled.div`
    padding: 23px 45px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    ${getMediaQueryFromTheme('md', 'xl')} {
        padding: 25px 40px;
    }

    ${getMediaQueryFromTheme('xs', 'md')} {
        padding: 25px 13px 13px 13px;
    }
`;

export const OfferTitle = styled.div`
    font-family: 'Times Modern', serif;
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    color: #01000d;
    margin: 0 0 8px 0;
    align-self: flex-start;

    ${getMediaQueryFromTheme('sm', 'xl')} {
        font-size: 26px;
        line-height: 30px;
    }

    ${getMediaQueryFromTheme('xs', 'sm')} {
        font-size: 20px;
        line-height: 22px;
        margin: 0 0 16px 0;
    }
`;

export const OfferDescription = styled.div`
    font-family: 'Roboto-Regular', 'Roboto-Regular-fallback', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 19px 0;
    max-width: 342px;

    ${getMediaQueryFromTheme('xs', 'md')} {
        font-size: 14px;
        line-height: 21px;
        margin: 0 0 20px 0;
    }
`;

export const SubscribeLinkButton = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #005c8a;
    border: 0;
    color: #fff;
    font-family: 'Roboto-Medium', sans-serif;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    max-width: 200px;
    line-height: 24px;
    margin: 0;
    padding: 12px 0;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.25s ease-in-out;
    align-self: baseline;

    &:hover {
        cursor: pointer;
        background-color: rgb(0, 82, 122);
    }

    &:active {
        background-color: rgb(0, 71, 107);
    }

    &:focus {
        outline: none;
    }

    ${getMediaQueryFromTheme('xs', 'sm')} {
        font-size: 14px;
        line-height: 21px;
        padding: 8px 12px;
    }

    ${props => props['data-variant'] === 'secondary' && css`
      background-color: #fff;
      color: #3B3B3B;
      border: 1px solid #3B3B3B;

      &:hover {
        background-color: #fafafa;
      }

      &:active {
        background-color: #f4f4f4;
      }
    `}
`;
