import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useInstrumentation, EventTrigger, InstrumentationEvent } from 'newskit';
import useCookies from '../useCookies';
import { PageEvent } from '@times-web/utils';

declare global {
  interface Window {
    siriusCapping: any,
    __capping: any,
    utag: {
      link(e: InstrumentationEvent['context']): void;
    }
  }
}
interface Props {
  trackingDetails?: PageEvent
}

const Capping = ({trackingDetails}: Props) => {
  const { fireEvent } = useInstrumentation();
  const [isRendered, setIsRendered] = useState(false);
  const {getCookieValue} = useCookies();
  const cappingDetected = (detected: boolean) => {
    const navigation_name = detected ? 'simultaneous reading above threshold' : 'simultaneous reading below threshold';
    const eventDetails = {
      event_category: 'user interaction',
      event_navigation_action: 'navigation',
      event_navigation_name: navigation_name,
      event_navigation_browsing_method: 'automated'
    }
    // leaving for testing purposes
    console.log('capping::BLOCKED')
        if (trackingDetails) {
          fireEvent({
            originator: 'Page',
            trigger: EventTrigger.Click,
            context: {
              ...trackingDetails,
              ...eventDetails,
            }
          });
        } else if (window && window.utag) {
          window.utag.link(eventDetails)
        }

      }
  useEffect(() => {
    window.__capping = {
      cappingDetected: cappingDetected.bind(null, true),
      cappingResolved: cappingDetected.bind(null, false),
    };
    setIsRendered(true);
  }, [])
  const authId = getCookieValue('authId');
  if (isRendered && authId) {
    return (
      <Helmet>
        <script>
          {`window.siriusCapping = window.siriusCapping || {};
            window.siriusCapping.config = {
              brand: "5c00f8c3-058a-490d-a9ed-9b23514b18d4",
              userId: "${authId}",
              mode: "reading",
              tolerance: 1,
              showPopinFunction: () => {
                window.__capping.cappingDetected();
              },
              hidePopinFunction: function () {
                window.__capping.cappingResolved();
                console.log('capping:UNBLOCKED')
              },            
        };`}
        </script>
        <script src={'https://capping.sirius.press/sdk.v1.1.0.js'}></script>
      </Helmet>
    )
  }
  return null;
}
export default Capping;
