import styled from '@emotion/styled';
import React from 'react';

const ScreenReader = styled.span`
    display: block;
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
    margin: -1px;
`;

export const ScreenReaderOnly = (props: {
    id?: string;
    children: React.ReactNode;
}) => <ScreenReader {...props} />;
