import axios from 'axios';

interface Subscription {
    serviceCancellationDateTime: string | null;
    startDate: string;
    isActive: boolean;
    proposition: {
        code: string;
    };
}

interface UserCancellationData {
    serviceCancellationDate: string | null;
    propositionCode: string;
}

const propositionCodesEligibleForResubscribe = [
    'MP218B',
    'MP444',
    'MP476',
    'MP238',
    'MP543',
    'MP286',
    'MP345',
    'MP218',
    'MP530',
    'MP575',
    'MP477',
    'MP89',
    'MP535',
    'MP1001',
    'MP478',
    'MP571',
    'MP511',
    'MP1000',
    'MP263',
    'MP390',
    'MP603',
    'MP525',
    'MP1007',
    'MP503',
    'MP97',
    'MP239',
    'MP368',
    'MP1010',
    'MP510',
    'MP524',
    'MP502',
    'MP479',
    'MP457',
    'MP471',
    'MP466',
    'MP159',
    'MP415',
    'MP327',
    'MP1039',
    'MP92',
    'MP281',
    'MP1006',
    'MP29',
    'MP459',
    'MP377',
    'MP558',
    'MP460',
    'MP570',
    'MP458',
    'MP316',
    'MP576',
    'MP1091',
    'MP264',
    'MP422',
    'MP344',
    'MP389',
    'MP442',
    'MP529',
    'MP284',
    'MP443',
    'MP294',
    'MP456',
    'MP1009',
    'MP426',
    'MP461',
    'MP248',
    'MP280',
    'MP293',
    'MP1095',
    'MP328',
    'MP580',
    'MP470',
    'MP134',
    'MP421',
    'MP557',
    'MP579',
    'MP572',
    'MP420',
    'MP381',
    'MP370',
    'MP414',
    'MP1093',
    'MP523',
    'MP267',
    'MP257',
];

const sortSubscriptionsByActive = (
    userSubscriptions: Subscription[]
): Subscription | null => {
    if (!userSubscriptions?.length) return null;

    const sortedSubscriptions = userSubscriptions.sort((a, b) => {
        // First, compare by isActive flag (true comes before false)

        return a.isActive === b.isActive
            ? new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
            : a.isActive
            ? -1
            : 1; // If isActive is different, prioritize the one that's true
    });

    return sortedSubscriptions[0];
};

const getUserCancellationStartDate = () => {
    // We don't have access to the user cancellation start date, so we'll store
    // the first time user is seeing the resubscribe modal as a start date in the local storage
    let userCancellationStartDate = localStorage.getItem(
        'userCancellationStartDate'
    );
    if (!userCancellationStartDate) {
        const currentDateAsIsoString = new Date().toISOString();
        localStorage.setItem(
            'userCancellationStartDate',
            currentDateAsIsoString
        );
        userCancellationStartDate = currentDateAsIsoString;
    }

    return userCancellationStartDate;
};

export const getUserSubscriptionData =
    async (): Promise<UserCancellationData | null> => {
        let userData = null;
        try {
            userData = await axios.get(
                '/api/get-subscription-cancellation-state',
                {
                    withCredentials: true,
                }
            );
        } catch (error) {
            console.error(error);
            return null;
        }

        const activeSubscription = sortSubscriptionsByActive(
            userData?.data?.user?.subscriptions
        );

        if (!activeSubscription) return null;

        return {
            serviceCancellationDate:
                activeSubscription.serviceCancellationDateTime,
            propositionCode: activeSubscription.proposition.code,
        };
    };

export const isUserEligibleForResubscribe = (
    userCancellationData: UserCancellationData,
    resubscribeModalDismissed: number
): boolean => {
    const cancellationDate = userCancellationData.serviceCancellationDate;
    const propositionCode = userCancellationData.propositionCode;

    // If there is not a cancellation date, the user is not in a cancelled state and
    // is not eligible for the resubscribe experiment
    if (!cancellationDate) return false;

    const currentDate = new Date();
    const cancellationDateToDateObject = new Date(cancellationDate);
    // If the cancellation date is in the past, the user subscription is not active (it's cancelled) and
    // they are not eligible for the resubscribe experiment
    if (currentDate > cancellationDateToDateObject) {
        return false;
    }

    const twoDaysFromNow = new Date(currentDate);
    twoDaysFromNow.setDate(currentDate.getDate() + 2);

    // If the cancellation date is in the future and within the next two days, the user is not
    // eligible for the resubscribe experiment
    if (
        cancellationDateToDateObject > currentDate &&
        cancellationDateToDateObject <= twoDaysFromNow
    ) {
        return false;
    }

    // If the user's proposition code is not in the list of propositions eligible for resubscribe
    // they are not eligible for the resubscribe experiment

    if (
        !propositionCodesEligibleForResubscribe.includes(
            propositionCode.toUpperCase()
        )
    ) {
        return false;
    }

    const userCancellationStartDate = getUserCancellationStartDate();

    if (resubscribeModalDismissed === 1) {
        // If the user has dismissed the offer on the first display,
        // the same message is to be displayed again on day 10 (calculated by day 10 that the
        // requested cancellation date was detected)

        const showModalDate = new Date(userCancellationStartDate);
        showModalDate.setDate(showModalDate.getDate() + 10);

        if (currentDate < showModalDate) {
            return false;
        }

        if (currentDate >= showModalDate) {
            return true;
        }
    }

    return true;
};
